import styled from 'styled-components';
import { themeGet } from 'styled-system';

const SectionWrapper = styled.section`
  padding: 75px 0 145px;
  @media only screen and (max-width: 1440px) {
    padding: 50px 0 75px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 30px 0 60px;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 0;
  }
  > div.container {
    display: flex;
    flex-wrap: wrap;
  }
  .image {
    width: calc(100% - 520px);
    @media only screen and (max-width: 991px) {
      width: calc(100% - 450px);
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const SectionHeader = styled.header`
  max-width: 560px;
  width: 100%;
  margin: 0 auto 20px;
  text-align: center;
  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    color: ${themeGet('colors.primary', '#2563FF')};
    text-transform: uppercase;
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: ${themeGet('colors.headingColor', '#0F2137')};
    margin: 0 0 20px 0;
    letter-spacing: -1px;
    @media only screen and (max-width: 1366px) {
      font-size: 28px;
      letter-spacing: -0.7px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
  }
  p {
    color: #6e7379;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 36px;
    text-align: left;
    @media only screen and (max-width: 1366px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TextWrapper = styled.div`
  width: 520px;
  align-self: center;
  @media only screen and (max-width: 1366px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    width: 450px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  h2 {
    color: ${themeGet('colors.headingColor', '#0F2137')};
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  p {
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
  > p {
    margin-bottom: 40px;
  }
  .feature__block {
    padding-top: 30px;
    transition: all 0.05s ease;
    .content__wrapper {
      margin-left: 10px;
    }
    h3 {
      color: ${themeGet('colors.headingColor', '#0F2137')};
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 9px;
      @media only screen and (max-width: 1366px) {
        line-height: 26px;
        margin-bottom: 7px;
      }
    }
  }
`;

export default SectionWrapper;
